const signatureFormat = /^[\p{L}\s'’-]+$/u

export function checkSignatureFormat(signature?: string) {
  if (!signature || signature === '') return true
  return signatureFormat.test(signature)
}

export function extractInvalidChars(signature?: string): string[] {
  if (!signature) return []
  const invalidChars = signature.match(/[^a-zA-Z\s'’-]/g)
  return invalidChars || []
}
