export enum UploadErrorType {
  MAX_SIZE = 'sizeError',
  GENERIC = 'genericError',
  NOT_ALLOWED_CHARACTERS = 'notAllowedCharacters',
  EMPTY_FILE_NAME = 'emptyFileName',
  STARTS_WITH_INVALID_CHARACTERS = 'startsWithInvalidCharacters',
  DOUBLE_PERIODS = 'doublePeriods',
}

const ALLOWED_FILENAME_PATTERN =
  /^[a-zA-Z0-9._()-]+( [a-zA-Z0-9._()-]+){0,100}$/

export function getFileNameErrorMessage(
  filename: string
): UploadErrorType | null {
  if (!filename || filename.trim() === '')
    return UploadErrorType.EMPTY_FILE_NAME
  if (filename.includes('..')) return UploadErrorType.DOUBLE_PERIODS
  if (
    filename.startsWith('.') ||
    filename.startsWith('-') ||
    filename.startsWith(' ')
  )
    return UploadErrorType.STARTS_WITH_INVALID_CHARACTERS
  if (!ALLOWED_FILENAME_PATTERN.test(filename))
    return UploadErrorType.NOT_ALLOWED_CHARACTERS
  return null
}
